<template>
  <div class="index">
    <div class="flex-center position-ref height-top">
      <div class="content-top"> 
        <div class="title m-b-md">{{title}}</div>
        <p class="lead">{{description}}</p>
        <div class="links-top">
          <span v-for="(name, key, index) in locales" :key="key">
            <span v-show="index != 0">&nbsp;/&nbsp;</span>
            <a :href="`/${key}`">{{name}}</a>
          </span>
        </div>
      </div>
    </div>
    <div class="flex-center position-ref height-bottom">
      <div class="content-bottom"> 
        <span class="links-bottom">
          <div>
            <span>Privacy Policy:&nbsp;</span>
            <span v-for="(name, key, index) in locales" :key="key">
              <span v-show="index != 0">&nbsp;/&nbsp;</span>
              <a :href="`/${key}/privacypolicy`">{{name}}</a>
            </span>
          </div>
          <div><a :href="`mailto:${email}`">Contact</a></div>
          <div>{{copyRight}}</div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  //import $ from 'jquery';
  export default {
    metaInfo : {
      title: `${window.application.title}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.meta_description}`}
      ]
    },
    data () {
      return {
        title: `${window.application.title}`,
        domain: `${window.application.domain}`,
        email: `${window.application.email}`,
        copyRight: `${window.application.copy_right}`,
        description: `${window.application.lang.meta_description}`,
        locales: window.application.locales
      }
    },
    mounted() {

    },
    updated() {

    },
    methods: {

    }
  }
</script>

<style>
  .jumbotron {
    text-align: center;
    background-color: transparent;
  }

  html, body {
    color: #ffffff;
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    height: 100vh;
    margin: 0;
  }

  .full-height {
    height: 100vh;
  }

  .height-top {
    height: 90vh;
  }

  .height-bottom {
    height: 10vh;
  }

  .flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .position-ref {
    position: relative;
  }

  .top-right {
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .content-top {
    text-align: center;
  }

  .content-bottom {
    font-size: 12px;
    text-align: center;
  }

  .title {
    font-size: 84px;
  }

  .links-top > a, .links-top > span > a {
    color: #ffffff;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  .links-bottom > a, .links-bottom > span > a {
    color: #ffffff;
    padding: 0 5px;
    font-size: 10px;
    /*font-weight: 600;
    letter-spacing: .1rem;*/
    text-decoration: none;
    text-transform: uppercase;
  }

  .m-b-md {
    margin-bottom: 30px;
  }

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {
    
  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
